<template>
  <validation-provider :rules="validate" v-slot="{ failed, errors }" :name="name" :immediate="immediate" :vid="vid">
    <div class="form-group" :class="{'has-error': errors.length, 'valid': !errors.length, 'has-prefix-append': prefix}"
      :style="{ marginBottom: `${marginBottom}px` }">
      <div class="input-group" :class="{'has-margin-top': label || margin}">
        <div class="input-group-prepend" v-if="prefix">
          <span class="input-group-text">{{prefix}}</span>
        </div>
        <input
          :type="realType"
          :step="step"
          :v-mask="mask"
          :required="required"
          :disabled="disabled"
          :name="name"
          :id="name"
          v-model="iValue"
          :placeholder="placeholder"
          :autocomplete="autocomplete"
          :maxlength="maxlength"
          :minlength="minlength"
          class="input"
          @change="() => $emit('change')"
          @focus="handleFocus()"
          @blur="handleBlur()"
        />
        <label class="control-label" :for="name">
          {{ label }}
          <a v-if="tooltip" v-b-tooltip.hover :title="tooltip" class="fa fa-question-circle tooltip-icon" />
        </label>
        <i v-if="!hideBar" class="bar"></i>
        <small v-show="errors.length" class="help text-danger">{{ errors[0] }}</small>
        <small class="help pt-1" v-if="help">{{ help }}</small>
      </div>
    </div>
  </validation-provider>
</template>
<script>

export default {
  props: {
    value: {
      required: true,
    },
    name: {
      required: false,
    },
    margin: {
      default: false,
      required: false,
    },
    label: {
      required: false,
    },
    required: {
      default: false,
      required: false,
    },

    help: {
      required: false,
    },
    disabled: {
      required: false,
      default: false
    },
    validate: {
      required: false,
      default: null
    },
    immediate: {
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    step: {
      type: String,
      required: false,
      default: "1",
    },
    autocomplete: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    tooltip: {
      type: String,
      default: null,
    },
    prefix: {
      type: String,
      default: null
    },
    vid: {
      type: String,
      default: '',
    },
    maxlength: {
      type: Number,
      default: undefined,
    },
    minlength: {
      type: Number,
      default: undefined,
    },
    marginBottom: {
      type: Number,
      default: undefined,
    },
    hideBar: {
      type: Boolean,
      default: false,
    }
  },
  created() {
  },
  data() {
    return {
      masks: {
        phone:'(###)###-####',
        tel: '(###)###-####',
        text: ''
      }
    }
  },
  computed: {
    realType() {
      if (this.type === 'phone') {
        return 'tel';
      }
      return this.type
    },
    mask() {
      return this.masks[this.type]
    },

    iValue: {
      get () { return this.value; },
      set (v) {
        this.$emit('input', v)
      },
    },

    // vid() {
    //   return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
    // }
  },
  methods: {
    handleFocus() {
      if (['widgetMobileButtonCall', 'widgetMobileButtonSms'].includes(this.name)) {
        this.$emit('focus', 'mobile-button');
      }
    },
    handleBlur() {
      this.$emit('blur');
    }
  }
};
</script>

<style lang="scss">
.form-group input:disabled ~ .control-label {
  color: #333;
  font-size: 0.6rem;
  font-weight: 600;
  text-transform: uppercase;
  top: -0.6rem;
  left: 0;
}
.form-group input:disabled {
  cursor: not-allowed;
  background-color: #f0f0f0;
}

.form-group .input-group.has-margin-top {
  margin-top: 20px !important;
}
.form-group .control-label {
  z-index: 0;
}
</style>
<style scoped lang="scss">
  .has-prefix-append {
    .input-group-prepend {
      display: inline-block;
      float: left;
    }
    input {
      width: calc(100% - 38px);
      display: inline-block;
      height: 100%;
      border-left: 0;
    }
  }
  .form-group input
  {
    line-height: 100%;
  }
  .form-group .control-label {
    pointer-events: unset;
    overflow: inherit;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .tooltip-icon {
    font-size: 16px;
    display: inline-block;
    margin-left: 5px;
    margin-top: calc( 4px - 8px);
  }
</style>
